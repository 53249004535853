export const deviceHelper = () => {
  return {
    isPC: isPC(),
    isAndroid: isAndroid(),
    isiOS: isiOS(),
    isFeiShu: isFeiShu(),
    isMobileFeiShu: isMobileFeiShu(),
    isPcFeiShu: isPcFeiShu(),
    isTablet: isTablet(),
    isWxWork: isWxWork(),
  };
};

// 判断是否为PC
function isPC() {
  const userAgentInfo = navigator.userAgent;
  const agents = [
    'Android',
    'iPhone',
    'SymbianOS',
    'Windows Phone',
    'iPad',
    'iPod',
  ];
  let flag = true;
  console.log(userAgentInfo);
  for (let i = 0; i < agents.length; i++) {
    if (userAgentInfo.indexOf(agents[i]) > -1) {
      flag = false;
      break;
    }
  }

  return flag;
}

// 判断是否为安卓
function isAndroid() {
  const userAgentInfo = navigator.userAgent;
  return (
    userAgentInfo.indexOf('Android') > -1 || userAgentInfo.indexOf('Adr') > -1
  );
}

// 判断是否为iOS
function isiOS() {
  const userAgentInfo = navigator.userAgent;
  return !!userAgentInfo.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
}

//判断是否是飞书
function isFeiShu() {
  const ua = navigator.userAgent.toLowerCase();
  return ua.indexOf('larklocale') > -1;
}
//判断是否是飞书pc端
function isPcFeiShu() {
  const ua = navigator.userAgent.toLowerCase();
  return ua.indexOf('webapp') > -1 && ua.indexOf('larklocale') > -1;
}

//判断是否是飞书手机端
function isMobileFeiShu() {
  const ua = navigator.userAgent.toLowerCase();
  return (
    ua.indexOf('mobile') > -1 &&
    ua.indexOf('larklocale') > -1 &&
    ua.indexOf('feishu') > -1
  );
}

//判断是否是平板设备
function isTablet() {
  /*  * ipad环境判断更新  * iOS pre 13 以前以ua作判断，13后以platform及maxTouchPoints做判断  */
  const isiPad =
    navigator.userAgent.match(/(iPad)/) ||
    (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);
  return isiPad;
}

/**
 * 判断是否是企业微信
 * @returns {isWxWork} boolean
 */
function isWxWork() {
  const isWxWork = /wxwork/i.test(navigator.userAgent);
  return isWxWork;
}

//转换时分秒
const formatDuration = (duration: number) => {
  const hours = Math.floor(duration / 3600);
  const minutes = Math.floor((duration % 3600) / 60);
  const seconds = Math.floor(duration % 60);

  const hours_one = hours.toString().padStart(2, '0');
  const minutes_one = minutes.toString().padStart(2, '0');
  const seconds_one = seconds.toString().padStart(2, '0');

  return `${hours_one}:${minutes_one}:${seconds_one}`;
};

export { isTablet, formatDuration };
