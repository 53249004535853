export function handleHashUrl() {
	const hashUrl = window.location.hash;

	if (hashUrl.startsWith("#/")) {
		const targetUrl = window.location.origin + hashUrl.substring(1);

		location.href = targetUrl;
	} else {
		return;
	}
}
